<template>
  <div>
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="container-fluid d-flex justify-content-between my-4 row">
      <h1 class="ml-3 my-0">View Event</h1>
      <router-link class="router-link" :to="{name: 'admin.event.edit' , params: { id: $route.params.id } }">
        <base-button type="secondary" class="py-2 font-weight-normal">
          Edit
        </base-button>
      </router-link>
    </div>
    <div class="mt-1 row px-3 mx-0" v-loading="loader">
      <div class="col-6">
        <card class="equal-height-columns" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div slot="header" class="d-flex">
            <div class="row w-100 mx-0 mt-2">
              <div class="d-inline-block">
                <h3 class="ellipsis-overflow-text card-heading">EVENT DETAIL</h3>
              </div>
              <div class="d-inline-block ml-4">
                <p class="show-status-class px-3 py-1 mb-0" :class="getStatusClass(response.event.status)">
                  {{ getStatus(response.event.status) }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Event Name</div>
            <div class="col-6">{{ response.event.event_title }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Event Type</div>
            <div class="col-6">{{ eventTypes[response.event.type] }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Start Time</div>
            <div class="col-6">{{ response.event.time }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">End Time</div>
            <div class="col-6">{{ response.event.end_time }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Date</div>
            <div class="col-6">{{ response.event.date | getFormattedDate }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Location</div>
            <div class="col-6">{{ getLocation }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Created Date</div>
            <div class="col-6">{{ response.event.created | getFormattedDate }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Modified Date</div>
            <div class="col-6">{{ response.event.modified | getFormattedDate }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Venue</div>
            <div class="col-6">{{ venueTypes[response.event.venue_type || ''] }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Weight Loss Plan</div>
            <div class="col-6">{{ weightLossTypes[response.event.weight_loss_type || ''] }}</div>
          </div>
        </card>
      </div>
      <div class="col-6">
        <card class="equal-height-columns" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div slot="header" class="d-flex">
            <div class="row w-100 mx-0 mt-2">
              <div class="d-inline-block">
                <h3 class="ellipsis-overflow-text card-heading">EVENT DESCRIPTION</h3>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <p class="px-4 overflow-auto event-description-max-height">
              <span class="value" v-html="response.event.event_description"/>
            </p>
          </div>
        </card>
      </div>
    </div>
    <div class="row px-3 mx-0">
      <div class="col-12 col-md-6">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <div class="d-flex flex-row mt-2 mb-3">
            <h3 class="mx-4 ellipsis-overflow-text card-heading">Patent Types</h3>
          </div>
          <div class="row ml-2 mb-2">
            <div v-for="patient_type in response.event.patient_type" :key="patient_type" class="col-6 mb-3" v-if="response.event.patient_type.length">
              {{ defaultPatientTypes[patient_type] }}
            </div>
            <div v-else>
              N/A
            </div>
          </div>
          <div class="d-flex flex-row mt-2 mb-3">
            <h3 class="mx-4 ellipsis-overflow-text card-heading">Groups to Remind</h3>
          </div>
          <div class="row ml-2 mb-2">
            <div v-for="stage in response.event.stages" :key="stage.id" class="col-6 mb-3">
              {{ defaultStages[stage] }}
            </div>
          </div>
          <div class="d-flex flex-row my-2">
            <h3 class="mx-4 ellipsis-overflow-text card-heading">Hosted By</h3>
          </div>
          <div class="row ml-2 mb-2">
            <p class="value ml-3 mr-4">{{ response.event.hosted_by || 'N/A' }}</p>
          </div>
          <div class="d-flex flex-row my-2">
            <h3 class="mx-4 ellipsis-overflow-text card-heading">Custom 5 Minute Reminder Message</h3>
          </div>
          <div class="row ml-2 mb-2">
            <p class="value ml-3 mr-4">{{ response.event.custom_reminder_message }}</p>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton"
import moment from "moment"

export default {
  name: "ShowEvent",
  components: {
    BackButton
  },
  data() {
    return {
      loader: false,
      response: {
        event: {...this.$store.getters['EventModule/getEvent']}
      },
      defaultPatientTypes: {
        'non_connected': 'Non Connected',
        'connected': 'Connected'
      },
      defaultStages: {
        '25': 'Considering Surgery',
        '50': 'Pre-Op',
        '75': 'Post-Op',
        '150': 'Non-Surgical',
      },
      venueTypes: {
        '': 'All',
        '1': 'In-Person',
        '2': 'Virtual',
      },
      weightLossTypes: {
        '': 'All',
        '1': 'Surgical',
        '2': 'Non-Surgical',
      },
      eventTypes: {
        1: 'Fixed Date',
        2: 'Weekly',
      },
    }
  },
  filters: {
    /**
     * Reformat created date
     * @param date
     * @returns {string}
     */
    getFormattedDate: function (date) {
      return date ? moment.utc(date).local().format('MMM DD, YYYY'): 'N/A'
    }
  },
  computed: {
    getLocation() {
      if (this.response.event.location == null) {
        return 'Other'
      } else if (this.response.event.admin_location) {
        return this.response.event.admin_location.name
      } else {
        return this.response.event.location
      }
    }
  },
  mounted() {
    this.getEvent()
  },
  methods: {
    /**
     * Get Event status
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'inactive-status-background' : 'active-status-background'
    },

    /**
     * Set Values for Event
     * @param event
     */
    setValues(event) {
      this.response.event = {...event}
      this.response.event.stages = []
      this.response.event.patient_type = event.patient_type?.split(',').filter((value) => {
        return value
      }) || [];

      const stages = event.stage.split(',').filter((value) => {
        return !!value && value != 0
      })

      stages.forEach(stage => {
        this.response.event.stages.splice(0, 0, stage)
      })
    },

    /**
     * Get Requested Event
     */
    getEvent() {
      let vm = this
      vm.loader = true
      const payload = {
        id: this.$route.params.id,
      }
      vm.$store.dispatch('EventModule/_getRequestedEvent', payload)
        .then(response => {
          this.setValues(response.data.data)
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Event',
            message: message
          })
          vm.$router.back()
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped>
.event-description-max-height {
  max-height: 405px;
}

.card-heading{
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
}
</style>
